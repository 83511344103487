.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 30%;
  background-color: rgba(255, 165, 0, 0.8);
  border-right: 4px solid rgba(255, 136, 0, 0.8);
  padding: 20px;
  border-radius: 0 10px 10px 0;
  font-family: 'Courier New', Courier, monospace;
  font-size: 4rem;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.sidebar.open {
  transform: translateX(0);
}

.hamburger-icon {
  position: fixed;
  left: 10px;
  top: 10px;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1000; /* Make sure the icon is clickable over other content */
}

/* Keep your other styles, ensuring they don't conflict with the updates */

@media only screen and (max-width: 996px) {
  .sidebar {
    width: 80%; /* Adjust based on preference for smaller screens */
  }
}
