@charset "utf-8";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
}

html {
  font-size: 10px;
  font-family: "Lato", Arial, sans-serif;
}

button{
  text-transform: uppercase;
}

.center{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.commingsoon{
  text-align: center;
  font-family: 'Sooky70s', sans-serif;
  font-size: 36px;
  color: #bf10ff;
  margin-bottom: 20px;
  animation: fadeOut 2s forwards; /* Apply fade-out animation */
}

.commingsoon2{
  text-align: center;
  font-family: 'Sooky70s', sans-serif;
  font-size: 36px;
  color: #bf10ff;
  margin-bottom: 20px;
  animation: fadeOut 4s forwards; /* Apply fade-out animation */
}

.contact{
  margin-top: 40px;
  font-size: 32px;
  color: #bf10ff;
  opacity: 0;
  animation: fade-in-contact 0.5s ease-in-out forwards; /* Använd animation för fade-in */
  animation-delay: 6s;
}


@media only screen and (max-width: 460px) { 
  .contact{
    margin-top: 20px;
    font-size: 16px;
  }
}


@keyframes fade-in-contact {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0% {
    opacity: 0; /* Start with full opacity */
  }
  20% {
    opacity: 0; /* Start with full opacity */
  }
  100% {
    opacity: 1; /* Fade to completely transparent */
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0; /* Start with full opacity */
  }
  80%{
    opacity: 0; /* Start with full opacity */
  }
  100% {
    opacity: 1; /* Fade to completely transparent */
  }
}