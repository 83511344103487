.section {
  width: 100%;
  }
  
  .category{
    display: none;
    text-align: center;
    font-family: 'Righteous', cursive;
    color: orange;
    font-size: 16em;
    font-size: bold;
    &--active{
      display: flex;
      justify-content: space-evenly;
      animation: fadeIn 1s ease-in forwards;
    }
  }

@media only screen and (max-width: 380px) {
  .section {
    width: 100%;
    .init__header {
    &__bojeng{
      font-size: 7em;
      padding: 10px;
      margin-top: 10px;
      opacity: 1;
      animation: zoomInitAnimationMobileSmall 3s ease-in forwards;
    }

    &__socialmedia {
      a{
        img{
          height: 40px;
        }
      }
      &--soundcloud {

      }
      &--youtube {
   
    }
      &--instagram {

    }
    }
  }
    .category{
      &__section {
        margin-left: 0px;
      }
    }
}
}
@media only screen and (min-width: 380px) and (max-width: 493px) {
  .section {
    width: 100%;
    .init__header {
    &__bojeng{
      font-size: 8em;
      padding: 10px;
      margin-top: 10px;
      opacity: 1;
      animation: zoomInitAnimationMobile 3s ease-in forwards;
    }
  }
    .category{
      &__section {
        margin-left: 0px;
      }
    }
}
}

/* Mobile Styles (up to 767px) */
@media only screen and (min-width: 493px) and (max-width: 991px) {
  
  .section {
      width: 100%;
      .init__header {
      &__bojeng{
        font-size: 12em;
        padding: 10px;
        margin-top: 10px;
        opacity: 1;
        animation: zoomInitAnimationMobileBig 3s ease-in forwards;
      }
    }
      .category{
        &__section {
          margin-left: 0px;
        }
      }
  }

  /* Additional mobile styles can be added here */
}

/* Tablet Styles (768px to 991px) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section {
    width: 100%;
    .init__header {
    &__bojeng{
      font-size: 16em;
      padding: 10px;
      margin-top: 10px;
      opacity: 1;
      animation: zoomInitAnimationTablet 3s ease-in forwards;
    }


  }

  &__socialmedia {
    a{
      img{
        height: 40px;
      }
    }
    &--soundcloud {
      left: 0%;
    }
    &--youtube {
      left: 55%;
  }
    &--instagram {
      left: 70%;
  }
  }
    .category{
      &__section {
        margin-left: 0px;
      }
    }
}

  /* Additional tablet styles can be added here */
}

/* Desktop Styles (992px and above) */
@media only screen and (min-width: 992px) {
  /* No need to adjust container padding for desktop */
  .section {
    .init__header {}
  /* Additional desktop styles can be added here */

}
}



@keyframes zoomInitAnimationTablet {
  0% {
    margin-top: 0px;
    margin-left: 0px;
    transform: scale(1.3);

  }
  80% {
    margin-top: 0px;
    margin-left: 0px;
    transform: scale(1.3);
  }
  85% {
    margin-top: 0px;
    margin-left: 0px;
    transform: scale(1.3);
  }
  100% {
    margin-top: -20%;
    margin-left: 0px;
    transform: scale(1);
  }
}
@keyframes zoomInitAnimationMobile {
  0% {
    margin-top: 50%;
    margin-left: 0px;
    transform: scale(1.1);

  }
  80% {
    margin-top: 50%;
    margin-left: 0px;
    transform: scale(1.1);
  }
  85% {
    margin-top: 50%;
    margin-left: 0px;
    transform: scale(1.2);
  }
  100% {
    margin-top: 20%;
    margin-left: 0px;
    transform: scale(1);
  }
}

@keyframes zoomInitAnimationMobileBig {
  0% {
    margin-top: 30%;
    margin-left: 0px;
    transform: scale(1.1);

  }
  80% {
    margin-top: 30%;
    margin-left: 0px;
    transform: scale(1.1);
  }
  85% {
    margin-top: 30%;
    margin-left: 0px;
    transform: scale(1.4);
  }
  100% {
    margin-top: 10%;
    margin-left: 0px;
    transform: scale(1);
  }
}

@keyframes zoomInitAnimationMobileSmall{
  0% {
    margin-top: 70%;
    margin-left: 0px;
    transform: scale(1.1);

  }
  80% {
    margin-top: 70%;
    margin-left: 0px;
    transform: scale(1.1);
  }
  85% {
    margin-top: 70%;
    margin-left: 0px;
    transform: scale(1.2);
  }
  100% {
    margin-top: 30%;
    margin-left: 0px;
    transform: scale(1);
  }
}

@keyframes fadeOutMain {
  0% {
    opacity: 1;
    position: absolute;
    margin-left: 31rem;

  }
  90% {
    opacity: 0;
    position: absolute;
    margin-left: 31rem;
  }
  100% {
    opacity: 0;
    display: none;
    margin-left: 31rem;
  }
}