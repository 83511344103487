.menu {
  list-style-type: none;
  margin: 20px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Adjust the space between items */

}

.menu-item {
  font-family: 'Sooky70s', sans-serif;
  padding: 10px 20px;
  font-size: 14em;
  color: #bf10ff;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0; /* Börja med opacity 0 för att skapa fade-in-effekt */
  animation: fade-in 0.5s ease-in-out forwards; /* Använd animation för fade-in */
}

/* Specificera fördröjning för varje ikon */
.menu-item:nth-child(1) {
  animation-delay: 4.2s; /* Första ikonen efter 3 sekunder */
}

.menu-item:nth-child(2) {
  animation-delay: 4.4s; /* Andra ikonen efter 3.5 sekunder */
}

.menu-item:nth-child(3) {
  animation-delay: 4.6s; /* Tredje ikonen efter 4 sekunder */
}

.menu-item:hover {
  transform: scale(1.1); /* Slightly enlarge the item */
  color: #dcdcdc; /* Change color on hover */
}

  
@media only screen and (max-width: 460px) { 
  .menu-item {
    font-size: 8em;
    
  }
}
@media only screen and (min-width: 460px) and (max-width: 768px) { 
  .menu-item {
    font-size: 8em;
    
  }
}
@media only screen and (min-width: 768px) and (max-width: 970px) {
  .menu-item {
    font-size: 8em;
    
  }
}

@media only screen and (min-width: 970px) and (max-width: 1070px) {
  .menu-item {
    font-size: 10em;
    
  }
}

@media only screen and (min-width:  1070px) {
  .menu-item {
    font-size: 12em;
    
  }
}