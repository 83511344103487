@font-face {
  font-family: 'Sooky70s';
  src: url('../../../public/fonts/Sooky70s.ttf') format('truetype');
}

.header {
  display: flex; /* Use Flexbox */
  min-height: 100vh;
  justify-content: space-evenly; /*Center horizontally */
  align-items: center;
  flex-direction: column;
  &__bojeng{
    cursor: pointer;
    font-family: 'Sooky70s', sans-serif;
    font-size: 20em;
    margin-top: 40px;
    color: orange;
    animation: zoomHeaderInitAnimation 3s ease-in forwards;
    text-align: center;
    z-index: 1;
    &--img{
      height: 200px;
      align-items: center;
      justify-content: center;
    }
  }

  .soundcloud{
    width:800px; 
    height:100px;
  }
  
  @media only screen and (max-width: 460px) { 
    &__bojeng {
      font-size: 12em;
      animation: zoomHeaderMobileInitAnimation 3s ease-in forwards;
      &--img{
        height: 100px;
      }
    }
  }
  @media only screen and (min-width: 460px) and (max-width: 768px) { 
    &__bojeng {
      font-size: 14em;
      animation: zoomHeaderMobileInitAnimation 3s ease-in forwards;
      &--img{
        height: 140px;
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 970px) {
    &__bojeng {
      font-size: 14em;
      &--img{
        height: 170px;
      }
    }
    
  }
  
  @media only screen and (min-width: 970px) and (max-width: 1070px) {
    &__bojeng {
      font-size: 18em;
      &--img{
        height: 190px;
      }
    }

  }

  @media only screen and (min-width:  1070px) {
    &__bojeng {
      font-size: 20em;
      animation: zoomHeaderDeskInitAnimation 3s ease-in forwards;
      &--img{
        height: 200px;
      }
    }
  }
}

// @keyframes zoomHeaderMobileInitAnimation {
//   0% {
//     transform: scale(1);
//     position: absolute;
//     top: 45%;
//   }
//   70% {
//     transform: scale(1);
//     position: absolute;
//     top: 45%;
//   }
//   85% {
//     transform: scale(1.3);
//     position: absolute;
//     top: 45%;
//   }
//   99% {
//     transform: scale(1);
//     position: absolute;
//     top: 5%;
//   }
//   100% {
//     transform: scale(1);
//   }
// }

// @keyframes zoomHeaderInitAnimation {
//   0% {
//     transform: scale(1.4);
//   }
//   80% {
//     transform: scale(1.4);
//   }
//   85% {
//     transform: scale(1.5);
//   }
//   100% {
//     transform: scale(1.2);
//   }
// }

// @keyframes zoomHeaderDeskInitAnimation {
//   0% {
//     transform: scale(2);
//     position: absolute;
//     top: 45%;
//   }
//   80% {
//     transform: scale(1.5);
//     position: absolute;
//     top: 45%;
//   }
//   85% {
//     transform: scale(1.6);
//     position: absolute;
//     top: 45%;
//   }
//   99% {
//     transform: scale(1.2);
//     position: absolute;
//     top: 10%;
//   }
//   100% {
//     transform: scale(1.2);
//   }
// }





