.header__social-media-icons{
  

.icon-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100px; /* Justera höjden efter behov */
}

.icon-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%; /* 4 kolumner */
  /* Specificera animation för varje ikon med fördröjning */
  opacity: 0; /* Börja med opacity 0 för att skapa fade-in-effekt */
  animation: fade-in 0.5s ease-in-out forwards; 
  color: #bf10ff;
  :hover {
    cursor: pointer;
    color: white;
  }
}

.icon {
  margin: 0px 40px;
  font-size: 10rem; /* Justera ikonens storlek efter behov */
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* Specificera fördröjning för varje ikon */
.icon-column:nth-child(1) {
  animation-delay: 4s; /* Första ikonen efter 3 sekunder */
}

.icon-column:nth-child(2) {
  animation-delay: 4.1s; /* Andra ikonen efter 3.5 sekunder */
}

.icon-column:nth-child(3) {
  animation-delay: 4.2s; /* Tredje ikonen efter 4 sekunder */
}

.icon-column:nth-child(4) {
  animation-delay: 4.3s; /* Fjärde ikonen efter 4.5 sekunder */
}

/* You can add more styling for the icons or container here */
@media only screen and (max-width: 486px) { 
  .icon {
    font-size: 6rem;
    margin: 0px 15px;
  }
}
@media only screen and (min-width: 486px) and (max-width: 768px) { 
  .icon {
    font-size: 8rem;
    margin: 0px 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 970px) {
  .icon {
    
  }
}

@media only screen and (min-width: 970px) and (max-width: 1070px) {
  .icon {
    
  }
}

@media only screen and (min-width:  1070px) {
  .icon {
    
  }
}
}